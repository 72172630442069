<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title class="text-h5 tw-bg-koderia tw-text-white">
        {{ $t('auth.subTitles.verification') }}
      </v-card-title>

      <v-card-text class="tw-mt-4">
        {{ $t('auth.descriptions.verification_dialog_message') }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <k-button
          class="tw-mr-2"
          color="primary"
          @click="sendVerificationEmail"
        >
          {{ $t('auth.actions.verify') }}
        </k-button>

        <k-button
          color="secondary"
          @click="close"
        >
          {{ $t('global.actions.close') }}
        </k-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KButton from '@web/components/core/k-button.vue'

export default {
  name: 'VerificationDialog',
  components: { KButton },
  computed: {
    dialog() {
      return this.$store.getters['getDialogValue']('verification')
    }
  },
  methods: {
    sendVerificationEmail() {
      this.$store.dispatch('AUTH/verification')
    },
    close() {
      this.$store.commit('setDialog', { name: 'verification', value: false })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
