<template>
    <svg :width="size" :height="size" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
            :fill="color"
            d="M17,8.85V11h1.667V6h-5V7.667h2.15L12.1,11.383a.83.83,0,0,1-1.175,0l-.975-.975a2.5,2.5,0,0,0-3.533,0L2,14.825,3.175,16l4.408-4.408a.83.83,0,0,1,1.175,0l.975.975a2.5,2.5,0,0,0,3.533,0Z"
            transform="translate(-0.333 -1)"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
