<template>
    <div class="wrapper">
        <div v-if="user" class="grid">
            <div class="divider"></div>
            <v-menu :close-on-click="true" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                    <div v-on="on" style="cursor: pointer;">
                        <ka-user-avatar :size="36" />
                    </div>
                </template>
                <v-list class="appbar-menu-list">
                    <div class="appbar-menu-item appbar-menu-item--user-name">
                        <router-link :to="{ name: 'profile' }">
                            <div class="user-name">{{ profile.firstName }} {{ profile.lastName }}</div>
                            <div v-if="profile.profession" class="user-profession" style="text-decoration: none !important;">
                                {{ profile.profession }}
                            </div>
                            <div v-else class="user-profession user-profession--empty" style="text-decoration: none !important;">
                                {{ $t('profile.actions.fill_position') }}
                            </div>
                        </router-link>
                    </div>
                    <hr />
                    <div class="appbar-menu-item">
                        <div class="icon-wrapper">
                            <k-icon name="add" />
                        </div>
                        <a @click.prevent="$emit('show-add-post', true)" class="ml-4">
                            {{ $t('global.actions.add_post') }}
                        </a>
                    </div>
                    <hr />
                    <div class="appbar-menu-item">
                        <div class="icon-wrapper">
                            <k-icon name="save" />
                        </div>
                        <router-link :to="{ name: 'profile-saved' }" class="ml-4">
                            {{ $t('global.titles.saved') }}
                        </router-link>
                    </div>
                    <div class="appbar-menu-item">
                        <div class="icon-wrapper">
                            <k-icon name="added" />
                        </div>
                        <router-link :to="{ name: 'profile-added' }" class="ml-4">
                            {{ $t('global.titles.added') }}
                        </router-link>
                    </div>
                    <hr />
                    <div class="appbar-menu-item">
                        <router-link v-if="hasUserCv" :to="{ name: 'profile-cv' }">
                            {{ $t('navigation.resume') }}
                        </router-link>
                        <router-link v-else :to="{ name: 'cv-landing-page' }">
                            {{ $t('navigation.resume') }}
                        </router-link>
                    </div>

                    <div class="appbar-menu-item">
                        <router-link :to="{ name: 'profile' }">
                            {{ $t('navigation.settings') }}
                        </router-link>
                    </div>

                    <div class="appbar-menu-item appbar-menu-item--sign-out">
                        <a @click.prevent="$store.dispatch('AUTH/signOut')">{{ $t('auth.actions.logout') }}</a>
                    </div>
                </v-list>
            </v-menu>
        </div>
        <div v-else class="grid grid--login">
            <template v-if="isNotCv">
                <div class="divider"></div>
                <router-link :to="{ name: 'auth-signin' }" @click="gaEventLogin" class="navbar-button-link no-right-margin">
                    {{ $t('auth.actions.sign_in_simple') }}
                </router-link>
                <k-button @click="gaEventRegister" :to="{ name: 'auth-signup' }" :appearance="$route.path.includes('/extra') ? 'primary' : 'primary-outlined'" class="text-uppercase">
                    {{ $t('auth.actions..sign_up') }}
                </k-button>
            </template>
        </div>
    </div>
</template>

<script>
import KaUserAvatar from "@web/components/ka-user-avatar";

export default {
    components: {
        KaUserAvatar
    },
    computed: {
        isNotCv() {
            return ["cv-show"].indexOf(this.$route.name) === -1;
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        hasUserCv() {
            return this.$store.state.USER.hasUserCv;
        },
        profile() {
            return this.$store.state.USER.profile || {};
        }
    },
    methods: {
        gaEventRegister() {
            // this.$ga.event({
            //     eventCategory: 'Header',
            //     eventAction: 'Click',
            //     eventLabel: 'Registrácia',
            //     eventValue: null
            // })
        },
        gaEventLogin() {
            // this.$ga.event({
            //     eventCategory: 'Header',
            //     eventAction: 'Click',
            //     eventLabel: 'Login',
            //     eventValue: null
            // })
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.grid {
    display: grid;
    grid-template-columns: 0.063rem 2.25rem;
    gap: 2rem;
    align-items: center;
    justify-items: center;
    justify-content: end;
}
.grid--login {
    grid-template-columns: 0.063rem auto auto;
}

.divider::before {
    content: "";
    display: block;
    height: 2.5rem;
    border-right: 0.063rem solid rgba(238, 238, 238, 0.5);
}

.appbar-menu-list {
    width: 18rem;
    padding: 2rem !important;
}

.icon-wrapper {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appbar-menu-item {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.user-profession {
    font-size: 0.875rem;
    color: $kod-grey;
}

.user-profession--empty {
    color: $kod-light-green;
}

.appbar-menu-item.appbar-menu-item--sign-out a {
    margin-bottom: 0 !important;
}

.appbar-menu-item.appbar-menu-item--user-name a {
    margin-top: 0 !important;
}

.appbar-menu-item--user-name a:hover {
    text-decoration: none !important;
}
</style>
