<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path :fill="color" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
