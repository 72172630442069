import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
const { functions, firestore } = firebase;

import store from "../store";

const FORM_STATES = {
    IDLE: "IDLE",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
};

const initialState = {
    formState: FORM_STATES.IDLE,
    interest: null,
    projects: []
};

store.registerModule("REFERRAL", {
    namespaced: true,
    state: initialState,
    actions: {
        SUBMIT_REFERRAL(ctx, payload) {
            ctx.commit("SET_FORM_STATE", FORM_STATES.PENDING);
            const project = payload.candidate.project ? ctx.state.projects.find(p => p.id === payload.candidate.project) : undefined;
            const data = {
                ...payload.contact,
                project: project || {},
                candidate: {
                    ...payload.candidate
                },
                type: "referral-interest"
            };

            const firebaseProcessExtra = functions().httpsCallable("processFormReferral");
            firebaseProcessExtra(data)
                .then(() => void ctx.commit("SET_FORM_STATE", FORM_STATES.SUCCESS))
                .catch(() => void ctx.commit("SET_FORM_STATE", FORM_STATES.ERROR));
        },
        async LOAD_PROJECTS(ctx) {
            const { projects } = ctx.state;

            if (!projects || !Array.isArray(projects) || projects.length <= 0) {
                const query = buildProjectsCollectionQuery();
                const { data } = await getCollection(query);
                ctx.commit("SET_PROJECTS", data);
                return data;
            }

            return projects;
        },
        // async LOAD_INTEREST(ctx, candidateId) {
        //     const query = firestore().doc(`interests/${candidateId}`);
        //     const { document } = await getDocument(query);
        //     ctx.commit("SET_INTEREST", document);
        // },
        async REMOVE_CANDIDATE(ctx, candidateId) {
            functions().httpsCallable("processFormReferralRemoveCandidate")(candidateId);
        }
    },
    mutations: {
        SET_FORM_STATE(state, formState) {
            state.formState = formState;
        },
        SET_PROJECTS(state, projects) {
            state.projects = projects;
        },
        SET_INTEREST(state, interest) {
            state.interest = interest;
        }
    }
});

// Helpers
const buildProjectsCollectionQuery = () => {
    let query = firestore()
        .collection("projects")
        .where("status", "==", "published");

    return query;
};

const getCollection = query => {
    let resultArray = [];
    return query
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                resultArray.push({ id: doc.id, ...doc.data() });
            });
            return { data: resultArray };
        })
        .catch(error => {
            return { error };
        });
};

// const getDocument = query => {
//     return query
//         .get()
//         .then(documentSnapshot => {
//             if (documentSnapshot.exists === false) return { document: null };
//             return { document: { id: documentSnapshot.id, ...documentSnapshot.data() } };
//         })
//         .catch(error => {
//             throw error;
//         });
// };
