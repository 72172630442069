import isToday from "date-fns/isToday";
import formatBase from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import formatRelativeBase from "date-fns/formatRelative";
import formatDistanceBase from "date-fns/formatDistance";
import { getLanguageLocale } from "@web/constants/language";

// -------------------- PRIVATE --------------------

/**
 * Converts the given input to a date or fails.
 *
 * @param {*} input
 * @returns {Date}
 */
function _toDate(input) {
    // Date
    if (input instanceof Date) {
        return input;
    }

    // Firebase Timestamp Object
    if (input?.seconds) {
        return new Date(input.seconds * 1000);
    }

    // Other
    return new Date(input);
}

// -------------------- PUBLIC --------------------

/**
 * Return the formatted date string in the given format.
 *
 * @param {Date|number} date The date to format.
 * @param {string} [format="PPP"] The string of tokens.
 * @param {object} [options={}] An object with options.
 *
 * @see https://date-fns.org/docs/format
 *
 * @returns {string} The formatted date string in the given format.
 */
export function formatDate(date, format = "PPP", options = {}) {
    try {
        return formatBase(_toDate(date), format, {
            locale: getLanguageLocale(),
            weekStartsOn: 1,
            ...options
        });
    } catch (e) {
        return "";
    }
}

/**
 * Represent the date in words relative to the given base date.
 *
 * @param {Date|number} date The date to format.
 * @param {Date|number} [to=Date.now()] The date to compare with.
 * @param {object} [options={}] An object with options.
 *
 * @see https://date-fns.org/docs/formatRelative
 *
 * @returns {string} The date in words relative to the given base date.
 */
export function formatDateRelative(date, to = Date.now(), options = {}) {
    try {
        return formatRelativeBase(_toDate(date), to, {
            locale: getLanguageLocale(),
            weekStartsOn: 1,
            ...options
        });
    } catch (e) {
        return "";
    }
}

/**
 * Eg.
 *     > 11:24
 *     > 15:30
 *     > 1d
 *     > 2d
 *     > 7d
 *     > 23.8.2020
 */
export function formatDateRelativeSimple(date) {
    try {
        const d = _toDate(date);
        if (isToday(d)) {
            return formatDate(d, "pp");
        } else if (differenceInDays(d, Date.now()) > -7) {
            return Math.abs(differenceInDays(d, Date.now())) + "d";
        } else {
            return formatDate(d, "PPP");
        }
    } catch (e) {
        return "";
    }
}

/**
 * Return the distance between the given dates in words.
 *
 * @param {Date|number} date The date to format.
 * @param {Date|number} [to=Date.now()] The date to compare with.
 * @param {object} [options={}] An object with options.
 *
 * @see https://date-fns.org/docs/formatDistance
 *
 * @returns {string} The distance between the given dates in words.
 */
export function formatDateDistance(date, to = Date.now(), options = {}) {
    try {
        return formatDistanceBase(_toDate(date), to, {
            locale: getLanguageLocale(),
            addSuffix: true,
            ...options
        });
    } catch (e) {
        return "";
    }
}

/**
 * Return a date range.
 *
 * @param {Date|number} dateA The date to format.
 * @param {Date|number} dateB The date to format.
 * @param {object} [options={}] An object with options.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatRange
 *
 * @returns {string} The date range.
 */
export function formatDateRange(dateA, dateB) {
    try {
        let fmt = new Intl.DateTimeFormat(getLanguageLocale(), {
            year: "numeric",
            month: "long",
            day: "numeric"
        });

        return fmt.formatRange(_toDate(dateA), _toDate(dateB));
    } catch (e) {
        return "";
    }
}

/**
 * Return a -1, 0 or 1.
 *
 * @param {Date|number} dateA The date to format.
 * @param {Date|number} dateB The date to format.
 *
 * @returns {number} -1,0 or 1.
 */
export function compareDates(dateA, dateB) {
    try {
        const formattedDateA = formatDate(dateA, "Y-MM-dd");
        const formattedDateB = formatDate(dateB, "Y-MM-dd");

        if (formattedDateA > formattedDateB) return 1;
        if (formattedDateA < formattedDateB) return -1;

        return 0;
    } catch {
        return 0;
    }
}
