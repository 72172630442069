import firebase from "firebase/app";
import "firebase/firestore";
const { firestore } = firebase;

import store from "../store";

const STATUS = {
    IDLE: "IDLE",
    LOADING: "LOADING"
};

const initialState = {
    status: STATUS.IDLE,
    items: [],
    // Internal do not use outside
    __cursor: {
        posts: null,
        projects: null
    },
    __end: {
        posts: null,
        projects: null
    }
};

const actions = {
    async LOAD_ITEMS({ commit, state }) {
        if (state.__end.posts && state.__end.projects) {
            return;
        }

        commit("SET_STATUS", STATUS.LOADING);

        try {
            // POSTS
            const posts = [];
            if (!state.__end.posts) {
                const postsQuery = buildPostsCollectionQuery({ cursor: state.__cursor.posts });
                const postsQuerySnapshot = await postsQuery.get();
                if (postsQuerySnapshot.size > 0) {
                    postsQuerySnapshot.forEach(postSnapshot => {
                        posts.push(postSnapshot.data());
                    });
                    const postsCursos = postsQuerySnapshot.docs.pop();
                    commit("SET_CURSOR", { name: "posts", value: postsCursos });
                } else {
                    commit("SET_END", { name: "posts", value: true });
                }
            }

            // PROJECTS
            const projects = [];
            if (!state.__end.projects) {
                const projectsQuery = buildProjectsCollectionQuery({ cursor: state.__cursor.projects });
                const projectsQuerySnapshot = await projectsQuery.get();
                if (projectsQuerySnapshot.size > 0) {
                    projectsQuerySnapshot.forEach(projectSnapshot => {
                        projects.push(projectSnapshot.data());
                    });
                    const projectsCursos = projectsQuerySnapshot.docs.pop();
                    commit("SET_CURSOR", { name: "projects", value: projectsCursos });
                } else {
                    commit("SET_END", { name: "projects", value: true });
                }
            }

            // ITEMS
            let items = [...state.items];
            while (projects.length > 0 || posts.length > 0) {
                // Add 1 project to items
                if (projects.length > 0) {
                    items.push(projects.shift());
                }

                // Add 2-4 posts to items
                const postsCount = randomNumber(2, 4);
                for (var i = 0; i < postsCount && posts.length > 0; i++) {
                    items.push(posts.shift());
                }
            }

            // Check if the first project is not too old.
            // If so then move it to the 3. position
            const xDaysAgo = Date.now() - 1000 * 60 * 60 * 24 * 5; // 5 days
            if (items[0] && items[0].type == "project" && items[0].data.timestamp < xDaysAgo) {
                items = move(items, 0, 2);
            }

            commit("SET_ITEMS", items);
            commit("SET_STATUS", STATUS.IDLE);
        } catch (error) {
            window.console.error(error);
            commit("SET_STATUS", STATUS.IDLE);
        }
    }
};

function move(array, from, to) {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
}

const mutations = {
    SET_STATUS(state, status) {
        state.status = status;
    },
    SET_ITEMS(state, items) {
        state.items = items;
    },
    SET_CURSOR(state, { name, value }) {
        state.__cursor[name] = value;
    },
    SET_END(state, { name, value }) {
        state.__end[name] = !!value;
    },
    RESET(state) {
        for (let prop in state) {
            state[prop] = initialState[prop];
        }
    }
};

store.registerModule("HOME", {
    namespaced: true,
    state: Object.assign({}, initialState),
    actions: {
        ...actions
    },
    mutations
});

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

const postConverter = {
    toFirestore: data => data,
    fromFirestore: (snapshot, options) => {
        const originalData = snapshot.data(options);
        const data = {
            id: snapshot.id,
            type: "post",
            data: {
                ...originalData,
                image: originalData.images ? originalData.images[0] : null,
                icon: originalData.favicons ? originalData.favicons[0] : null,
                timestamp: originalData.publishedTimestamp,
                link: originalData.url,
                id: snapshot.id
            }
        };
        return snapshot.exists ? data : null;
    }
};

const projectConverter = {
    toFirestore: data => data,
    fromFirestore: (snapshot, options) => {
        const data = {
            id: snapshot.id,
            type: "project",
            data: {
                ...snapshot.data(options),
                id: snapshot.id
            }
        };
        return snapshot.exists ? data : null;
    }
};

const buildPostsCollectionQuery = ({ cursor = null }) => {
    const now = Date.now();

    let query = firestore()
        .collection("links")
        .where("status", "==", "PUBLISHED")
        .where("publishedTimestamp", "<=", now)
        .orderBy("publishedTimestamp", "desc")
        .withConverter(postConverter)
        .limit(15);

    if (cursor) {
        query = query.startAfter(cursor);
    }

    return query;
};

const buildProjectsCollectionQuery = ({ cursor = null }) => {
    let query = firestore()
        .collection("projects")
        .where("status", "==", "published")
        .orderBy("publishedAt", "desc")
        .withConverter(projectConverter)
        .limit(5);

    if (cursor) {
        query = query.startAfter(cursor);
    }

    return query;
};
