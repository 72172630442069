<template>
    <button class="wrapper" :style="`width: ${size}rem; height: ${size}rem`">
        <KoderiaClose :size="Number(size) * 0.5" color="white" />
    </button>
</template>

<script>
import KoderiaClose from "@web/components/core/icons/koderia_close";
export default {
    props: {
        size: {
            type: [String, Number],
            default: 24
        }
    },
    components: {
        KoderiaClose
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.wrapper {
    border-radius: 9999rem;
    background-color: $kod-black;
}
</style>
