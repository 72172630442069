import axios from "axios";
import config from "@shared/config";

const api = axios.create({
    baseURL: config.apiUrl
})

/*
api.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
 */

function setBearerToken(token) {
    api.defaults.headers['Authorization'] = `Bearer ${token}`
}

function clearBearerToken() {
    api.defaults.headers['Authorization'] = undefined
}

export { api, setBearerToken, clearBearerToken }
