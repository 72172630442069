import Vue from "vue";
import VueI18n from "vue-i18n";
import { DEFAULT_LOCALE } from "@web/constants/language";
import en from "../lang/en";
import sk from "../lang/sk";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: DEFAULT_LOCALE,
    fallbackLocale: "en",
    messages: {
        sk,
        en
    }
});

export default i18n;
