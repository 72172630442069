class KoderiaError extends Error {
  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

const ERROR_MESSAGE_PREFIX = "global.errors.";

const firebaseErrors = {
  "already-exists": "already_exists",
  // "auth/app-deleted": "",
  "auth/expired-action-code": "auth_expired_action_code",
  "auth/invalid-action-code": "auth_invalid_action_code",
  "auth/user-disabled": "auth_user_disabled",
  "auth/user-not-found": "auth_user_not_found",
  "auth/weak-password": "auth_weak_password",
  "auth/email-already-in-use": "auth_email_already_in_use",
  "auth/invalid-email": "auth_invalid_email",
  "auth/operation-not-allowed": "auth_operation_not_allowed",
  "auth/account-exists-with-different-credential":
    "auth_account_exists_with_different_credential",
  // "auth/auth-domain-config-required": "",
  // "auth/credential-already-in-use": "",
  // "auth/operation-not-supported-in-this-environment": "",
  // "auth/timeout": "",
  // "auth/missing-android-pkg-name": "",
  // "auth/missing-continue-uri": "",
  // "auth/missing-ios-bundle-id": "",
  // "auth/invalid-continue-uri": "",
  // "auth/unauthorized-continue-uri": "",
  // "auth/invalid-dynamic-link-domain": "",
  // "auth/argument-error": "",
  // "auth/invalid-persistence-type": "",
  // "auth/unsupported-persistence-type": "",
  "auth/invalid-credential": "auth_invalid_credential",
  "auth/wrong-password": "auth_wrong_password",
  // "auth/invalid-verification-code": "",
  // "auth/invalid-verification-id": "",
  // "auth/custom-token-mismatch": "",
  // "auth/invalid-custom-token": "",
  // "auth/captcha-check-failed": "",
  "auth/invalid-phone-number": "auth_invalid_phone_number",
  "auth/missing-phone-number": "auth_missing_phone_number",
  // "auth/quota-exceeded": "",
  "auth/cancelled-popup-request": "auth_cancelled_popup_request",
  "auth/popup-blocked": "auth_popup_blocked",
  "auth/popup-closed-by-user": "auth_popup_closed_by_user",
  // "auth/unauthorized-domain": "",
  // "auth/invalid-user-token": "",
  "auth/user-token-expired": "auth_user_token_expired",
  // "auth/null-user": "",
  // "auth/app-not-authorized": "",
  "auth/invalid-api-key": "auth_invalid_api_key",
  "auth/network-request-failed": "auth_network_request_failed",
  "auth/requires-recent-login": "auth_requires_recent_login",
  "auth/too-many-requests": "auth_too_many_requests",
  // "auth/web-storage-unsupported": "",
  // "auth/invalid-claims": "",
  // "auth/claims-too-large": "",
  // // "auth/id-token-expired": "",
  // "auth/id-token-revoked": "",
  // "auth/invalid-argument": "",
  // "auth/invalid-creation-time": "",
  // "auth/invalid-disabled-field": "",
  // "auth/invalid-display-name": "",
  // "auth/invalid-email-verified": "",
  // "auth/invalid-hash-algorithm": "",
  // "auth/invalid-hash-block-size": "",
  // "auth/invalid-hash-derived-key-length": "",
  // "auth/invalid-hash-key": "",
  // "auth/invalid-hash-memory-cost": "",
  // "auth/invalid-hash-parallelization": "",
  // "auth/invalid-hash-rounds": "",
  // "auth/invalid-hash-salt-separator": "",
  // "auth/invalid-id-token": "",
  // "auth/invalid-last-sign-in-time": "",
  // "auth/invalid-page-token": "",
  "auth/invalid-password": "auth_invalid_password",
  // "auth/invalid-password-hash": "",
  // "auth/invalid-password-salt": "",
  // "auth/invalid-photo-url": "",
  // "auth/invalid-provider-id": "",
  // "auth/invalid-session-cookie-duration": "",
  // "auth/invalid-uid": "",
  // "auth/invalid-user-import": "",
  // "auth/invalid-provider-data": "",
  // "auth/maximum-user-count-exceeded": "",
  // "auth/missing-hash-algorithm": "",
  // "auth/missing-uid": "",
  // "auth/reserved-claims": "",
  // "auth/session-cookie-revoked": "",
  // "auth/uid-alread-exists": "",
  "auth/email-already-exists": "auth_email_already_exists",
  "auth/phone-number-already-exists": "auth_phone_number_already_exists",
  // "auth/project-not-found": "",
  "auth/insufficient-permission": "auth_insufficient_permission",
  "auth/internal-error": "auth_internal-error",
  "permission-denied": "permission_denied"
};

const koderiaErrors = {
  //
};

function errorTranslate(e) {
  if (typeof e === "object" && typeof e.code === "string") {
    if (e.code in firebaseErrors) {
      e.message = `${ERROR_MESSAGE_PREFIX}${firebaseErrors[e.code]}`;
    } else if (e.code in koderiaErrors) {
      e.message = koderiaErrors[e.code];
    }
  }

  return e;
}

export { errorTranslate as default, KoderiaError };
