export default {
    data() {
        return {
            isMobile: false,
            isDesktop: false,
            windowWidth: window.innerWidth || 0
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.__onResize);
        });
        this.__onResize();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.__onResize);
    },
    watch: {
        windowWidth: {
            immediate: true,
            handler(newWidth) {
                if (newWidth > 1023) {
                    this.isDesktop = true;
                    this.isMobile = false;
                } else {
                    this.isDesktop = false;
                    this.isMobile = true;
                }
            }
        }
    },
    methods: {
        __onResize() {
            this.windowWidth = window.innerWidth;
        },
        isScreenLessThen(value) {
            return this.windowWidth < value;
        }
    }
};
