<template>
    <div class="tw-flex">
        <div v-if="isMobile" class="tw-flex tw-items-center lang-wrapper-mobile">
            <button
                v-for="lang in languages"
                :key="lang.value"
                :class="['lang-btn', { 'lang-btn-active': lang.value === locale }]"
                @click="changeLocale(lang.value)"
            >
                {{ lang.textShort }}
            </button>
        </div>

        <k-language-switcher-menu
            v-else
            :selected-language="selectedLanguage"
            :languages="languages"
            @onLanguageSelect="changeLocale($event)"
            v-bind="$attrs"
        />
    </div>
</template>

<script>
import Vue from "vue";
import { DEFAULT_LOCALE } from "@web/constants/language";
import KLanguageSwitcherMenu from "@web/components/core/k-language-switcher-menu";
import { routerReplaceAfterLanguageChange } from "@web/utils/router";

export default {
    name: "k-language-nav-switcher",
    components: { KLanguageSwitcherMenu },
    data() {
        return {
            languages: [
                {
                    text: "Slovak",
                    textShort: "SK",
                    value: DEFAULT_LOCALE
                },
                {
                    text: "English",
                    textShort: "EN",
                    value: "en"
                }
            ]
        };
    },
    computed: {
        locale() {
            return this.$store.getters.appLocale;
        },
        selectedLanguage() {
            const selectedLanguage = this.languages.find(language => language.value === this.locale);

            return selectedLanguage.text || "Slovak";
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        changeLocale(locale) {
            if (Vue.$CookieConsent) {
                Vue.$CookieConsent.setLanguage(locale);
            }

            if (this.locale === locale) {
                return;
            }

            routerReplaceAfterLanguageChange(locale);
        }
    }
};
</script>

<style lang="scss" scoped>
.menu {
    top: unset;
}

.lang-wrapper-mobile {
    column-gap: 30px;
}

.lang-btn {
    border: none;
    background-color: transparent;

    &:focus,
    &:focus-visible {
        outline: none !important;
    }
}

.lang-btn-active {
    color: $koderia;
}
</style>
