<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            :fill="color"
            d="M21.429,32H2.571A2.571,2.571,0,0,0,0,34.571V53.429A2.571,2.571,0,0,0,2.571,56H9.924V47.841H6.549V44H9.924V41.073c0-3.329,1.982-5.169,5.018-5.169a20.446,20.446,0,0,1,2.974.259v3.268H16.241a1.92,1.92,0,0,0-2.165,2.075V44h3.685l-.589,3.841h-3.1V56h7.353A2.571,2.571,0,0,0,24,53.429V34.571A2.571,2.571,0,0,0,21.429,32Z"
            transform="translate(0 -32)"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
