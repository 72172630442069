<template>
    <div>
        <div class="tw-fixed tw-bg-white tw-z-10 tw-w-full tw-mx-auto tw-px-4 lg:tw-px-8">
            <div class="tw-flex tw-justify-between tw-h-16 lg:tw-h-20">
                <div class="tw-flex tw-justify-between tw-align-middle tw-w-full">
                    <div class="tw-flex tw-items-center tw-flex-shrink-0">
                        <router-link :to="{ name: 'Home', params: { locale }}">
                            <k-logo icon class="tw-block tw-w-auto tw-h-12 lg:tw-hidden" />
                            <k-logo class="tw-hidden tw-w-auto tw-h-12 lg:tw-block" />
                        </router-link>
                    </div>

                    <k-language-switcher />
                </div>
            </div>
        </div>
        <div class="tw-relative">
            <main class="tw-pt-16 sm:tw-pt-0 tw-w-full tw-h-full tw-min-h-screen tw-bg-white tw-flex tw-items-center tw-justify-center">
                <slot></slot>
            </main>
        </div>
    </div>
</template>
<script>
import KLanguageSwitcher from '@web/components/core/k-language-nav-switcher'
import {getRouteLocale} from '@web/constants/language'
export default {
    components: {KLanguageSwitcher},
    computed: {
        locale() {
            return getRouteLocale()
        }
    }
}
</script>
