/* eslint-disable */
import firebase from "firebase/app";
import "firebase/firestore";
import {isEmpty} from "lodash";

const { firestore } = firebase;

const postConverter = {
    toFirestore: data => data,
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
            image: data.images ? data.images[data.images.length - 1] : null,
            icon: data.favicons ? data.favicons[0] : null
        };
    }
};

const eventConverter = {
    toFirestore: data => data,
    fromFirestore: (snapshot, options) => {
        if (snapshot.exists) {
            const data = snapshot.data(options) || {};
            const { startAt, endAt } = data;
            return {
                ...data,
                startAtDate: startAt ? startAt.toDate() : null,
                endAtDate: endAt ? endAt.toDate() : null,
                id: snapshot.id
            };
        }

        return null;
    }
};

const projectConverter = {
    toFirestore: data => data,
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id
        };
    }
};

/**
 * Fetch posts by ids
 *
 * @param {String[]} ids IDs of posts to fetch.
 */
async function fetchPostsByIds(ids = []) {
    const fetchItemsPromises = ids.map(async id => {
        const post = await firestore()
            .doc(`links/${id}`)
            .withConverter(postConverter)
            .get();
        return post.data();
    });

    const items = await Promise.all(fetchItemsPromises);

    // Filter falsy items
    return items.filter(Boolean);
}

/**
 * Fetch events by ids
 *
 * @param {String[]} ids IDs of events to fetch.
 */
async function fetchEventsByIds(ids = []) {
    const fetchItemsPromises = ids.map(async id => {
        const item = await firestore()
            .doc(`events/${id}`)
            .withConverter(eventConverter)
            .get();
        return item.data();
    });

    const items = await Promise.all(fetchItemsPromises);

    // Filter falsy items
    return items.filter(Boolean);
}

/**
 * Fetch projects by ids
 *
 * @param {String[]} ids IDs of projects to fetch.
 */
async function fetchProjectsByIds(ids = []) {
    const fetchItemsPromises = ids.map(async id => {
        const item = await firestore()
            .doc(`projects/${id}`)
            .withConverter(projectConverter)
            .get();
        return item.data();
    });

    const items = await Promise.all(fetchItemsPromises);

    // Filter falsy items
    return items.filter(Boolean);
}

async function fetchCountries() {
    try {
        const res = await fetch("https://restcountries.com/v3.1/all");
        const json = await res.json();
        const data = json
            .map(country => {
                const { name, cca2 } = country || {};
                const { common } = name || {};
                return { name: common, code: String(cca2).toLowerCase() };
            })
            .sort((a, b) => String(a.name).localeCompare(b.name));
        return data;
    } catch (err) {
        console.error(err);
        return [];
    }
}

async function fetchCountriesPhones() {
    try {
        const res = await fetch("https://restcountries.com/v3.1/all")
        const json = await res.json()

        return json
            .filter(({idd}) => !isEmpty(idd))
            .map(country => {
                const { name, cca3, idd } = country
                const { common } = name
                const { root, suffixes } = idd

                return {
                    name: common,
                    code: cca3,
                    phoneCode: `${root}${suffixes[0]}`
                };
            })
            .sort((a, b) => String(a.name).localeCompare(b.name))
    } catch (err) {
        console.error(err)
        return []
    }
}

export { fetchPostsByIds, fetchEventsByIds, fetchProjectsByIds, fetchCountries, fetchCountriesPhones, postConverter, eventConverter, projectConverter };
