<template>
    <v-layout v-if="prompt && prompt.active" row justify-center>
        <v-dialog :value="prompt.active" persistent max-width="640">
            <form @submit.prevent="confirm">
                <v-card class="dialog">
                    <h3 class="medium-heading-text has-ruler">{{ prompt.title }}</h3>
                    <p class="body-text" v-if="prompt.body">{{ prompt.body }}</p>
                    <k-input autofocus v-model="value" :type="prompt.inputType" />
                    <div class="mt-8">
                        <k-button color="primary" type="submit">
                            {{ $t('global.actions.confirm') }}
                        </k-button>

                        <k-button class="ml-4" @click.native="storno">
                            {{ $t('global.actions.back') }}
                        </k-button>
                    </div>
                </v-card>
            </form>
        </v-dialog>
    </v-layout>
</template>

<script>
import "./store";

export default {
    data() {
        return {
            value: null
        };
    },
    computed: {
        prompt() {
            return this.$store.state.PROMPT;
        }
    },
    methods: {
        confirm() {
            this.prompt.resolve(this.value);
            this.value = null;
            this.$store.commit("PROMPT/DEACTIVATE");
        },
        storno() {
            this.prompt.resolve(undefined);
            this.value = null;
            this.$store.commit("PROMPT/DEACTIVATE");
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog {
    padding: 4rem;
    border-radius: 0.125rem !important;
}
.has-ruler {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 0.063rem solid #ededf1;
}
</style>
