<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path :fill="color" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
