import store from '../store'
import { fetchExpertises, fetchExpertiseTypes, fetchLanguages, fetchProfessions } from '@web/api/common-api'
import { fetchCountriesPhones } from '@web/services'
import { fetchUtmSources } from '@web/api/auth-api'

const state = {
  professions: [],
  expertises: [],
  expertiseTypes: [],
  languages: [],
  languageLevels: [],
  countriesPhones: [],
  utmSources: []
}

const getters = {
  getExpertises: (state) => state.expertises.map(exp => {
    const { id, ...rest } = exp

    return {
      expertiseId: id,
      ...rest
    }
  }),
  getExpertiseTypes: (state) => state.expertiseTypes,
  getProfessions: (state) => state.professions,
  getProfessionsWithoutOther: (state) => state.professions.filter(profession => profession.value !== 'OTHER'),
  getLanguages: (state) => state.languages,
  getLanguageLevels: (state) => state.languageLevels,
  getCountriesPhones: (state) => state.countriesPhones,
  getDefaultPhoneCountry: (state) => state.countriesPhones.find(cPc => cPc.code === 'SVK'),
  getUtmSources: (state) => state.utmSources
}

const actions = {
  async fetchExpertises({ commit }) {
    const response = await fetchExpertises()

    commit('setExpertises', response)
  },
  async fetchExpertiseTypes({ commit }) {
    const response = await fetchExpertiseTypes()

    commit('setExpertiseTypes', response)
  },
  async fetchProfessions({ commit }) {
    const response = await fetchProfessions()

    commit('setProfessions', response)
  },
  async fetchLanguages({ commit }) {
    const { languages, languageLevels } = await fetchLanguages()

    commit('setLanguages', languages)
    commit('setLanguageLevels', languageLevels)
  },
  async fetchCountriesPhones({ commit }) {
    const response = await fetchCountriesPhones()

    commit('setCountriesPhones', response)
  },
  async fetchUtmSources({ commit }) {
    const response = await fetchUtmSources()

    commit('setUtmSources', response)
  },
  loadEnums({ dispatch }) {
    dispatch('fetchExpertises')
    dispatch('fetchExpertiseTypes')
    dispatch('fetchProfessions')
  }
}

const mutations = {
  setExpertises(state, payload) {
    state.expertises = payload
  },
  setExpertiseTypes(state, payload) {
    state.expertiseTypes = payload
  },
  setProfessions(state, payload) {
    state.professions = payload
  },
  setLanguages(state, payload) {
    state.languages = payload
  },
  setLanguageLevels(state, payload) {
    state.languageLevels = payload
  },
  setCountriesPhones(state, payload) {
    state.countriesPhones = payload
  },
  setUtmSources(state, payload) {
    state.utmSources = payload
  }
}

store.registerModule('ENUMS', {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})
