<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path :fill="color" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
