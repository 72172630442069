import { render, staticRenderFns } from "./k-language-nav-switcher.vue?vue&type=template&id=632bbca3&scoped=true&"
import script from "./k-language-nav-switcher.vue?vue&type=script&lang=js&"
export * from "./k-language-nav-switcher.vue?vue&type=script&lang=js&"
import style0 from "./k-language-nav-switcher.vue?vue&type=style&index=0&id=632bbca3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632bbca3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KLanguageSwitcherMenu from '@web/components/core/k-language-switcher-menu'
installComponents(component, {KLanguageSwitcherMenu})
