import Vue from 'vue'
import Vuex from 'vuex'
import { DEFAULT_LOCALE } from '@web/constants/language'
import i18n from '@web/assets/i18n'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,
  modules: {
    namespaced: true
  },
  state: {
    route: {
      prev: {},
      current: {},
      pending: false
    },
    appbar: '',
    locale: DEFAULT_LOCALE,
    dialogs: {
      verification: false
    }
  },
  actions: {
    ROUTE_CHANGE_START: (ctx, payload) => {
      ctx.commit('ROUTE_CHANGE', {
        route: payload,
        pending: true
      })
    },
    ROUTE_CHANGE_END: (ctx, payload) => {
      ctx.commit('ROUTE_CHANGE', {
        route: payload,
        pending: false
      })
    },
    APPBAR_STYLE: (ctx, style) => {
      ctx.commit('SET_APPBAR_STYLE', style)
    },
    APPBAR_TRANSPARENTABLE: ctx => {
      ctx.commit('SET_APPBAR_STYLE', 'transparent-bg')
    },
    APPBAR_NON_TRANSPARENTABLE: ctx => {
      ctx.commit('SET_APPBAR_STYLE', '')
    },
    SET_APP_LANGUAGE: (ctx, payload) => {
      i18n.locale = payload
      ctx.commit('SET_LOCALE', payload)
    }
  },
  mutations: {
    ROUTE_CHANGE: (state, routeInfo) => {
      state.route = {
        prev: state.route,
        current: routeInfo.route,
        pending: routeInfo.pending
      }
    },
    SET_APPBAR_STYLE: (state, style) => {
      state.appbar = style
    },
    SET_LOCALE: (state, locale) => {
      state.locale = locale
    },
    setDialog: (state, { name, value }) => {
      state.dialogs[name] = value
    }
  },
  getters: {
    appLocale: state => state.locale,
    getDialogValue: state => name => state.dialogs[name]
  }
})

store.syncWithReouter = function (router) {
  router.beforeEach((to, from, next) => {
    let route = cleanRoute(to)
    route.from = cleanRoute(from)
    store.dispatch('ROUTE_CHANGE_START', route)
    next()
  })
  router.afterEach((to, from) => {
    let route = cleanRoute(to)
    route.from = cleanRoute(from)
    store.dispatch('ROUTE_CHANGE_END', route)
  })
}

function cleanRoute(route) {
  return {
    fullPath: route.fullPath,
    hash: route.hash,
    name: route.name,
    params: route.params,
    path: route.path,
    query: route.query
  }
}

export default store
