import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export default {
    install: (app, pluginConfig) => {
        app.$CookieConsent = CookieConsent;
        app.$CookieConsent.run(pluginConfig);
    }
};

export const en = {
    consentModal: {
        title: "Cookie usage",
        description:
            "We use cookies and other technologies to improve your browsing experience on our website, to show you customized content and targeted ads, to analyze traffic to our website and to understand where our visitors come from.",
        acceptAllBtn: "Accept all",
        showPreferencesBtn: "Manage preferences"
    },
    preferencesModal: {
        title: "Cookie usage",
        acceptAllBtn: "Accept all",
        acceptNecessaryBtn: "Reject all",
        savePreferencesBtn: "Save preferences",
        closeIconLabel: "Close",
        sections: [
            {
                description:
                    "Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for various purposes and to improve your online experience on our website."
            },
            {
                title: "Strictly necessary cookies",
                description:
                    "These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly",
                linkedCategory: "necessary"
            },
            {
                title: "Analytics and Marketing cookies",
                description:
                    "These cookies are used to collect information to analyze traffic on our website and how visitors use our website. For example, these cookies can track how much time you spend on the website or the pages you visit, which helps us understand how we can improve our website for you.",
                linkedCategory: "analytics"
            },
            {
                title: "More information",
                description:
                    'For any queries in relation to our policy on cookies and your choices, please read our <a class="cc-link" href="/en/privacy-policy">Privacy Policy</a>.'
            }
        ]
    }
};

export const sk = {
    consentModal: {
        title: "Používame cookies",
        description:
            "Súbory cookie a ďalšie technológie používame na zlepšenie vášho zážitku z prehliadania našich webových stránok, na to, aby sme vám zobrazovali prispôsobený obsah a cielené reklamy, na analýzu návštevnosti našich webových stránok a na pochopenie toho, odkiaľ naši návštevníci prichádzajú.",
        acceptAllBtn: "Súhlasím",
        showPreferencesBtn: "Nastavenia"
    },
    preferencesModal: {
        title: "Vaše súkromie je pre nás dôležité",
        acceptAllBtn: "Súhlasím",
        acceptNecessaryBtn: "Nesúhlasím",
        savePreferencesBtn: "Uložiť nastavenia",
        closeIconLabel: "Zatvoriť",
        sections: [
            {
                description:
                    "Súbory cookie sú veľmi malé textové súbory, ktoré sa ukladajú do vášho počítača pri návšteve webovej stránky. Súbory cookie používame na rôzne účely a na zlepšenie vášho online zážitku z našej webovej stránky."
            },
            {
                title: "Technické cookies",
                description:
                    "Tieto súbory cookie sú nevyhnutné na to, aby sme vám mohli poskytovať služby dostupné prostredníctvom našej webovej stránky a aby ste mohli používať určité funkcie našej webovej stránky. Bez týchto súborov cookie vám nemôžeme poskytnúť určité služby na našom webe.",
                linkedCategory: "necessary"
            },
            {
                title: "Analytické a marketingové cookies",
                description:
                    "Tieto súbory cookie sa používajú na zhromažďovanie informácií na analýzu prenosu na našom webe a toho, ako návštevníci používajú náš web. Tieto súbory cookie môžu napríklad sledovať napríklad to, koľko času strávite na webových stránkach alebo navštívených stránkach, čo nám pomáha pochopiť, ako môžeme pre vás vylepšiť naše webové stránky.",
                linkedCategory: "analytics"
            },
            {
                title: "Viac informácií",
                description:
                    'Ak máte akékoľvek otázky týkajúce sa súborov cookie a vašich možností, prečítajte si naše <a class="cc-link" href="/ochrana-osobnych-udajov">Zásady ochrany osobných údajov</a>.'
            }
        ]
    }
};
