<template>
    <div :class="'kod-header-container ' + header.containerClass" :style="{ backgroundImage: `url(${header.headerImage})` }">
        <div class="kod-header">
            <h2>{{ header.heading }}</h2>
            <p class="leader-text">
                {{ header.lead }}
            </p>
            <p v-if="header.projectInfo" class="project-description small-text">
                <span>
                    <k-icon name="location" size="18" class="mr-2" />
                    <span>
                        {{ header.projectInfo.location }}
                    </span>
                </span>
                <span>
                    <k-icon name="euro" size="18" class="mr-2" />
                    <span v-if="header.projectInfo.specialRate === undefined || header.projectInfo.specialRate === ''">
                        {{ (header.projectInfo.rate * 20 - 200) | moneyNumber }} – {{ (header.projectInfo.rate * 20 + 200) | moneyNumber }} €
                    </span>
                    <span v-else>
                        {{ header.projectInfo.specialRate }}
                    </span>
                </span>
                <span>
                    <k-icon name="work" size="18" class="mr-2" />
                    <span>
                        {{ $t('global.trade') }} / S.R.O.
                    </span>
                </span>
            </p>
            <p v-else-if="$route.path.includes('praca/')" class="project-description small-text">
                ...
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "kod-header",
    props: ["header"],
    filters: {
        moneyNumber: function(rate) {
            return rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }
};
</script>

<style lang="scss">
@import "@web/sass/variables.scss";
.kod-header-container {
    width: 100%;
    background-size: 100% 100% !important;
    background-position: top !important;
    background-repeat: no-repeat;

    &.standard {
        color: white;
        background-color: $kod-black;
    }

    &.colors-inverted {
        @include padding-top-multiplier(20);
        @include padding-bottom-multiplier(20);

        background: transparent;

        color: $koderia-black;

        .v-breadcrumbs__item {
            color: $koderia-grey;
        }
        .v-breadcrumbs__item--disabled {
            color: $koderia-black !important;
        }
        .v-breadcrumbs__divider {
            padding: 0 0.25rem;
            color: $koderia-grey;
        }
    }
    &.standard_without-black-bg {
        color: white;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    background-position: center;
    background-size: cover;

    .kod-header {
        width: 100%;
        text-align: center;
        @include padding-top-multiplier(16);
        @include padding-bottom-multiplier(12);

        .project-description {
            @include margin-top-multiplier(1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > span {
                display: flex;
                align-items: center;

                @include margin-top-multiplier(1);

                &:last-child {
                    @include margin-right-multiplier(0);
                }

                .v-icon {
                    line-height: 1.125rem;
                    font-size: 1.125rem;
                    color: white !important;

                    @include margin-right-multiplier(1);
                }
            }
        }
        // .v-breadcrumbs{

        //     @include margin-top-multiplier(4);

        //     padding : 0;
        //     user-select: none;

        //     .v-breadcrumbs__item,
        //     .v-breadcrumbs__divider{
        //         font-size: map-get(map-get($text, extra-small), size);
        //         line-height: map-get(map-get($text, extra-small), line-height);
        //     }

        //     .v-breadcrumbs__item{
        //         color : $koderia-grey ;
        //     }
        //     .v-breadcrumbs__item--disabled{
        //         color : white;
        //     }
        //     .v-breadcrumbs__divider{
        //         padding : 0 0.25rem;
        //         color : $koderia-grey;
        //     }
        //   }
    }
}

.kod-header h2 {
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
    font-weight: 800;
}

@media (min-width: 64rem) {
    .project-description {
        flex-direction: row !important;
        > span {
            @include margin-top-multiplier(0);
            @include margin-right-multiplier(4);
        }
    }
}
</style>
