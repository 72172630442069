import { api } from './config'

async function registerUser(user) {
  const { data } = await api.post('/registration/candidate', user)

  return data
}

async function fetchUtmSources() {
  const { data } = await api.get('/utm-sources')

  return data
}

export {
  registerUser,
  fetchUtmSources
}
