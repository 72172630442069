/* eslint-disable */
import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";

import store from "../store";

const COMMON_ALLOWED_ATTRIBUTES = ["createdAt", "updatedAt", "archivedAt"];
const TAG_ALLOWED_ATTRIBUTES = ["name", ...COMMON_ALLOWED_ATTRIBUTES];
const CATEGORY_ALLOWED_ATTRIBUTES = ["name", ...COMMON_ALLOWED_ATTRIBUTES];
const AUTHOR_ALLOWED_ATTRIBUTES = ["name", "description", "avatar", ...COMMON_ALLOWED_ATTRIBUTES];
const POST_ALLOWED_ATTRIBUTES = [
    "title",
    "category",
    "draft",
    "author",
    "tags",
    "featuredImage",
    "content",
    "excerpt",
    "publishedAt",
    ...COMMON_ALLOWED_ATTRIBUTES
];

function pick(obj, keys) {
    return keys.map(k => (k in obj && obj[k] !== undefined ? { [k]: obj[k] } : {})).reduce((res, o) => Object.assign(res, o), {});
}

const createConverter = (allowedAttributes = []) => ({
    toFirestore: modelObject => {
        return pick(modelObject, allowedAttributes);
    },
    fromFirestore: (snapshot, options) => {
        if (snapshot.exists) {
            const data = snapshot.data(options) || {};
            return {
                ...data,
                id: snapshot.id
            };
        }

        return null;
    }
});

const createCollectionFetcher = (collectionName, dataConverter) => async () => {
    const collectionSnapshot = await firebase
        .firestore()
        .collection(collectionName)
        .withConverter(dataConverter)
        .get();

    return collectionSnapshot.docs.map(doc => doc.data());
};

const createDocumentFetcher = (collectionName, dataConverter) => async documentId => {
    const documentSnapshot = await firebase
        .firestore()
        .collection(collectionName)
        .doc(documentId)
        .withConverter(dataConverter)
        .get();

    return documentSnapshot.data();
};

const fetchActionCreator = (fetcher, callback) => async (ctx, payload) => {
    try {
        callback(ctx, await fetcher(payload));
    } catch (error) {
        console.error(error);
    }
};

const tagConverter = createConverter(TAG_ALLOWED_ATTRIBUTES);
const postConverter = createConverter(POST_ALLOWED_ATTRIBUTES);
const authorConverter = createConverter(AUTHOR_ALLOWED_ATTRIBUTES);
const categoryConverter = createConverter(CATEGORY_ALLOWED_ATTRIBUTES);

/**
 * Returns a list of all blog posts.
 */
const fetchAllPosts = async () => {
    const collectionSnapshot = await firebase
        .firestore()
        .collection("blog")
        .withConverter(postConverter)
        .where("draft", "==", false)
        .where("publishedAt", "<=", new Date().toISOString())
        .get();

    return collectionSnapshot.docs.map(doc => doc.data());
};

/**
 * Returns a chunk of blog posts and last loaded document.
 */
const fetchNextPosts = async ({ limit, startAfter }) => {
    let collectionQuery = firebase
        .firestore()
        .collection("blog")
        .withConverter(postConverter)
        .orderBy("publishedAt", "desc")
        .where("draft", "==", false)
        .where("publishedAt", "<=", new Date().toISOString())
        .limit(limit || 10);

    if (startAfter) {
        collectionQuery = collectionQuery.startAfter(startAfter);
    }

    const collectionSnapshot = await collectionQuery.get();

    return {
        data: collectionSnapshot.docs.map(doc => doc.data()),
        last: collectionSnapshot.docs.pop()
    };
};

/**
 * Returns a list of all blog post tags.
 */
const fetchAllTags = createCollectionFetcher("blog_tags", tagConverter);

/**
 * Returns a list of all blog post authors.
 */
const fetchAllAuthors = createCollectionFetcher("blog_authors", authorConverter);

/**
 * Returns a list of all blog post categories.
 */
const fetchAllCategories = createCollectionFetcher("blog_categories", categoryConverter);

/**
 * Returns the post based on the ID.
 *
 * @param {string} id - The blog post id.
 */
const fetchPostById = createDocumentFetcher("blog", postConverter);

/**
 * Returns the post based on the slug.
 *
 * @param {object} payload
 * @param {string} payload.slug - The blog post slug.
 * @param {string} payload.accessKey - The blog post access key.
 */
const fetchPostBySlug = async ({ slug, accessKey }) => {
    let collectionSnapshot = null;

    if (accessKey) {
        collectionSnapshot = await firebase
            .firestore()
            .collection("blog")
            .where("slug", "==", slug)
            .where("accessKey", "==", accessKey)
            .withConverter(postConverter)
            .limit(1)
            .get();
    } else {
        collectionSnapshot = await firebase
            .firestore()
            .collection("blog")
            .where("slug", "==", slug)
            .where("draft", "==", false)
            .where("publishedAt", "<=", new Date().toISOString())
            .withConverter(postConverter)
            .limit(1)
            .get();
    }

    const documentSnapshot = collectionSnapshot.docs.pop();
    return documentSnapshot ? documentSnapshot.data() : null;
};

/**
 * Returns the tag based on the ID.
 *
 * @param {string} id - The tag id.
 */
const fetchTagById = createDocumentFetcher("blog_tags", tagConverter);

/**
 * Returns the author based on the ID.
 *
 * @param {string} id - The author ID.
 */
const fetchAuthorById = createDocumentFetcher("blog_authors", authorConverter);

/**
 * Returns the category based on the ID.
 *
 * @param {string} id - The category ID.
 */
const fetchCategoryById = createDocumentFetcher("blog_categories", categoryConverter);

const initialState = {
    tagsById: {},
    postsById: {},
    authorsById: {},
    categoriesById: {},
    lastLoadedPost: undefined
};

const actions = {
    async fetchAll({ dispatch }) {
        await Promise.all([dispatch("fetchAllTags"), dispatch("fetchAllPosts"), dispatch("fetchAllAuthors"), dispatch("fetchAllCategories")]);
    },

    // FETCH COLLECTION
    fetchAllTags: fetchActionCreator(fetchAllTags, (ctx, data) => ctx.commit("SET_TAGS", data)),
    fetchAllPosts: fetchActionCreator(fetchAllPosts, (ctx, data) => ctx.commit("SET_POSTS", data)),
    fetchInitialPosts: async (ctx, payload) => {
        if (ctx.lastLoadedPost === void 0) {
            await ctx.dispatch("fetchNextPosts", { limit: payload ? payload.limit : 12 });
        }
    },
    fetchNextPosts: async (ctx, payload) => {
        try {
            const { data, last } = await fetchNextPosts({
                limit: payload ? payload.limit : undefined,
                startAfter: ctx.state.lastLoadedPost
            });

            (data || []).forEach(item => {
                ctx.commit("ADD_POST", item);
            });

            ctx.commit("SET_LAST_LOADED_POST", last);
        } catch (error) {
            console.error(error);
        }
    },
    fetchAllAuthors: fetchActionCreator(fetchAllAuthors, (ctx, data) => ctx.commit("SET_AUTHORS", data)),
    fetchAllCategories: fetchActionCreator(fetchAllCategories, (ctx, data) => ctx.commit("SET_CATEGORIES", data)),

    // FETCH DOCUMENT
    fetchTagById: fetchActionCreator(fetchTagById, (ctx, item) => ctx.commit("ADD_TAG", item)),
    fetchPostById: fetchActionCreator(fetchPostById, (ctx, item) => ctx.commit("ADD_POST", item)),
    fetchPostBySlug: fetchActionCreator(fetchPostBySlug, (ctx, item) => ctx.commit("ADD_POST", item)),
    fetchAuthorById: fetchActionCreator(fetchAuthorById, (ctx, item) => ctx.commit("ADD_AUTHOR", item)),
    fetchCategoryById: fetchActionCreator(fetchCategoryById, (ctx, item) => ctx.commit("ADD_CATEGORY", item))
};

const getters = {
    allPosts: state => Object.values(state.postsById || {}).sort((c1, c2) => new Date(c2.publishedAt).getTime() - new Date(c1.publishedAt).getTime()),
    getPostById: state => id => state.postsById[id],
    getPostBySlug: (state, getters) => slug => getters.allPosts.find(post => post.slug === slug),

    allTags: state => Object.values(state.tagsById || {}).sort((c1, c2) => c1.name.localeCompare(c2.name)),
    allTagNames: (state, getters) => getters.allTags.map(({ name }) => name),
    getTagById: state => id => state.tagsById[id],

    allAuthors: state => Object.values(state.authorsById || {}).sort((c1, c2) => c1.name.localeCompare(c2.name)),
    allAuthorNames: (state, getters) => getters.allAuthors.map(({ name }) => name),
    getAuthorById: state => id => state.authorsById[id],

    allCategories: state => Object.values(state.categoriesById || {}).sort((c1, c2) => c1.name.localeCompare(c2.name)),
    allCategoryNames: (state, getters) => getters.allCategories.map(({ name }) => name),
    getCategoryById: state => id => state.categoriesById[id]
};

const mutations = {
    SET_LAST_LOADED_POST(state, payload) {
        state.lastLoadedPost = payload || null;
    },
    SET_TAGS(state, payload) {
        state.tagsById = {};

        (payload || []).forEach(item => {
            if (item && item.id) {
                Vue.set(state.tagsById, item.id, item);
            }
        });
    },
    SET_POSTS(state, payload) {
        state.postsById = {};

        (payload || []).forEach(item => {
            if (item && item.id) {
                Vue.set(state.postsById, item.id, item);
            }
        });
    },
    SET_AUTHORS(state, payload) {
        state.authorsById = {};

        (payload || []).forEach(item => {
            if (item && item.id) {
                Vue.set(state.authorsById, item.id, item);
            }
        });
    },
    SET_CATEGORIES(state, payload) {
        state.categoriesById = {};

        (payload || []).forEach(item => {
            if (item && item.id) {
                Vue.set(state.categoriesById, item.id, item);
            }
        });
    },
    ADD_TAG(state, item) {
        if (item && item.id) {
            Vue.set(state.tagsById, item.id, item);
        }
    },
    ADD_POST(state, item) {
        if (item && item.id) {
            Vue.set(state.postsById, item.id, item);
        }
    },
    ADD_AUTHOR(state, item) {
        if (item && item.id) {
            Vue.set(state.authorsById, item.id, item);
        }
    },
    ADD_CATEGORY(state, item) {
        if (item && item.id) {
            Vue.set(state.categoriesById, item.id, item);
        }
    }
};

store.registerModule("BLOG", {
    namespaced: true,
    state: Object.assign({}, initialState),
    actions,
    getters,
    mutations
});
