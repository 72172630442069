import firebase from "firebase/app";
import "firebase/auth";
const { auth } = firebase;
const { GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, EmailAuthProvider } = auth;

import { getCurrentUser } from "../firebase";
import store from "../store";

/**
 * List of available providers.
 */
const supportedProviders = [GoogleAuthProvider.PROVIDER_ID, FacebookAuthProvider.PROVIDER_ID, GithubAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID];

/**
 * Return the Google Auth Provider instance with scopes.
 */
function getGoogleAuthProvider() {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");
    return provider;
}

/**
 * Return the Facebook Auth Provider instance with scopes.
 */
function getFacebookAuthProvider() {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");
    // provider.addScope("user_gender");
    return provider;
}

/**
 * Return the Github Auth Provider instance with scopes.
 */
function getGithubAuthProvider() {
    const provider = new GithubAuthProvider();
    return provider;
}

/**
 * Return the Email Auth Provider instance with scopes.
 */
function getEmailAuthProvider() {
    const provider = new EmailAuthProvider();
    return provider;
}

/**
 * Returns provider instance by the given provider ID.
 *
 * @param {string} providerId
 */
function getProvider(providerId) {
    switch (providerId) {
        case auth.GoogleAuthProvider.PROVIDER_ID:
            return getGoogleAuthProvider();
        case auth.FacebookAuthProvider.PROVIDER_ID:
            return getFacebookAuthProvider();
        case auth.GithubAuthProvider.PROVIDER_ID:
            return getGithubAuthProvider();
        case auth.EmailAuthProvider.PROVIDER_ID:
            return getEmailAuthProvider();
        default:
            throw new Error(`No provider implemented for ${providerId}`);
    }
}

async function performReauthentication() {
    // try {
    const user = await getCurrentUser();
    const providers = await auth().fetchSignInMethodsForEmail(user.email);
    const firstPopupProviderMethod = providers.find(p => supportedProviders.includes(p));

    if (!firstPopupProviderMethod) {
        throw new Error("Your account is linked to a provider that isn't supported.");
    }

    if (firstPopupProviderMethod !== EmailAuthProvider.PROVIDER_ID) {
        const linkedProvider = getProvider(firstPopupProviderMethod);
        return await user.reauthenticateWithPopup(linkedProvider);
    }

    const password = await store.dispatch("PROMPT/ASK", {
        title: "Heslo",
        body: "Pre potvrdenie zadajte svoje aktuálne heslo.",
        inputType: "password"
    });
    const credentials = EmailAuthProvider.credential(user.email, password);
    return await user.reauthenticateWithCredential(credentials);
    // } catch (error) {
    //     throw error;
    // }
}

async function performOAuthLoginWithProvider(provider) {
    try {
        return await auth().signInWithPopup(provider);
    } catch (error) {
        if (!(error.email && error.credential && error.code === "auth/account-exists-with-different-credential")) {
            throw error;
        }

        const providers = await auth().fetchSignInMethodsForEmail(error.email);
        const firstPopupProviderMethod = providers.find(p => supportedProviders.includes(p));

        if (!firstPopupProviderMethod) {
            throw new Error("Your account is linked to a provider that isn't supported.");
        }

        const linkedProvider = getProvider(firstPopupProviderMethod);

        /**
         * To link EmailAuthProvider with OAuth provider see link below.
         * @see https://firebase.google.com/docs/auth/web/google-signin#handling-account-exists-with-different-credential-errors
         */
        if (firstPopupProviderMethod === EmailAuthProvider.PROVIDER_ID) {
            throw new Error("An account with this email already exists.");
        }

        linkedProvider.setCustomParameters({ login_hint: error.email });

        const result = await auth().signInWithPopup(linkedProvider);
        result.user.linkWithCredential(error.credential);

        return result;
    }
}

export { getProvider, supportedProviders, performOAuthLoginWithProvider, performReauthentication };
