<template>
    <div class="tw-flex tw-items-center">
        <k-menu :label="selectedLanguage" v-bind="$attrs">
            <button
                v-for="lang in languages"
                :key="lang.value"
                :class="['lang-btn', { 'lang-btn-active': lang.text === selectedLanguage }]"
                @click="$emit('onLanguageSelect', lang.value)"
            >
                {{ lang.text }}
            </button>
        </k-menu>
    </div>
</template>

<script>
export default {
    name: 'k-language-switcher-menu',
    props: {
        selectedLanguage: {
            type: String
        },
        languages: {
            type: Array,
            default: () => []
        }
    },
    emits: ['onLanguageSelect'],
}
</script>

<style lang="scss" scoped>
.lang-btn {
    border: none;
    background-color: transparent;
    text-align: left;

&:focus,
&:focus-visible {
     outline: none !important;
 }
}

.lang-btn-active {
    color: $koderia;
}
</style>
