/*
 * Global Components Register
 */

import Vue from "vue";

import KodAppbar from "./nav/kod-navigation-appbar.vue";
Vue.component("kod-appbar", KodAppbar);

import KodHeader from "./nav/kod-header.vue";
Vue.component("kod-header", KodHeader);
