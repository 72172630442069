<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path :fill="color" d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M17,13H7v-2h10V13z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
