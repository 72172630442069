<template>
    <svg :width="size" :height="size" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-656 -490)">
            <path
                d="M13.6,9.9,10.538,8.587a.656.656,0,0,0-.766.189L8.416,10.432A10.135,10.135,0,0,1,3.571,5.587L5.228,4.231a.655.655,0,0,0,.189-.766L4.1.4a.661.661,0,0,0-.752-.38L.509.679A.656.656,0,0,0,0,1.319,12.686,12.686,0,0,0,12.688,14.006a.656.656,0,0,0,.64-.509l.656-2.844A.664.664,0,0,0,13.6,9.9Z"
                transform="translate(659 492.994)"
                :fill="color"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
