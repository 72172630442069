<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            :fill="color"
            d="M12,12.25A2.25,2.25,0,1,0,9.75,10,2.253,2.253,0,0,0,12,12.25Zm4.5,4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5,2.25V17h9ZM19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V5H19Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
