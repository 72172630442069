<template>
    <v-avatar color="#f3f4f6" v-bind="$attrs" :size="size">
        <v-img v-if="photo" :src="photo" />
        <div v-else class="tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-full" :style="{ width: `${size}px`, height: `${size}px`, padding: `${size / 3}px`}">
            <k-icon name="user" :size="size / 2" />
        </div>
    </v-avatar>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        size: {
            type: Number,
            default: 32
        }
    },
    computed: {
        iconSize() {
            return parseInt(this.size) * 0.5;
        },
        ...mapGetters({
            profile: 'USER/getUserProfile'
        }),
        photo() {
            if (this.profile?.avatar) {
                return this.profile.avatar;
            } else {
                return null;
            }
        }
    }
};
</script>

<style></style>
