<template>
    <div v-if="initialized">
        <v-app-bar
            v-if="isNotCv"
            app
            sticky
            id="kodAppBar"
            ref="appbar"
            :class="appbarStyle === 'transparent-dark/light-logo' ? 'transparent-dark' : appbarStyle"
        >
            <!-- koderia logo  -->
            <v-toolbar-title @click="$router.push({ path: '/' })" style="cursor: pointer;">
                <ka-logo :light="(appbarStyle === 'transparent-light' || appbarStyle === 'transparent-dark/light-logo') && windowTop <= 0" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="navbar-desktop">
                <router-link
                    v-for="(route, index) in routes.filter(e => e.menu === 'first')"
                    :key="index"
                    :to="{ name: route.name }"
                    :class="$route.path === route.path ? 'navbar-button-link active' : 'navbar-button-link'"
                >
                    {{ route.title }}
                </router-link>

                <a href="https://koderia.sk/blog" target="_blank" rel="noopener" class="navbar-button-link">{{ $t('navigation.blog') }}</a>

                <router-link
                    :to="{ name: hasUserCv ? 'profile-cv' : 'cv-landing-page' }"
                    :class="$route.name === 'cv-landing-page' ? 'navbar-button-link active' : 'navbar-button-link'"
                >
                    <span>{{ $t('navigation.resume') }}<sup class="cv-sup">{{ $t('global.new') }}</sup></span>
                </router-link>

                <!-- more activator -->
                <v-menu bottom y-offset="true">
                    <template v-slot:activator="{ on }">
                        <button text v-on="on" :ripple="false" class="navbar-button-link">
                            {{ $t('global.actions.more') }}
                            <k-icon name="arrow_drop_down" size="20" style="margin-left: 0.5rem;" />
                        </button>
                    </template>
                    <v-list class="appbar-menu-list">
                        <div class="appbar-menu-item">
                            <router-link class="no-margin" :to="{ name: 'Questionaire' }">{{ $t('navigation.adequate_salary') }}</router-link>
                            <router-link :to="{ name: 'Calculator' }">{{ $t('navigation.calculator') }}</router-link>
                        </div>
                        <hr />
                        <div class="appbar-menu-item">
                            <router-link :to="{ name: 'extra' }">{{ $t('navigation.koderia_extra') }}</router-link>
                            <router-link :to="{ name: 'referral' }">
                                Koderia
                                <span class="referral">Referral</span>
                            </router-link>
                            <router-link :to="{ name: 'dictionary-index' }">
                                {{ $t('navigation.koderia_dictionary') }}
                            </router-link>
                        </div>
                        <hr />
                        <div class="appbar-menu-item">
                            <router-link :to="{ name: 'KoderiaAbout' }">{{ $t('navigation.what_is_koderia') }}</router-link>
                            <!-- <index-link :to="{ name: 'AboutUs' }">O nás</index-link> -->
                            <router-link :to="{ name: 'TermsAndConditions' }">{{ $t('navigation.terms_conditions') }}</router-link>
                        </div>
                        <hr />
                        <div class="appbar-menu-item">
                            <div class="appbar-social">
                                <a target="_blank" href="https://www.facebook.com/koderiask/" rel="noopener noreferrer">
                                    <k-icon name="facebook" size="20" />
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/koderiask/" rel="noopener noreferrer">
                                    <k-icon name="linkedin" size="20" />
                                </a>
                                <a target="_blank" href="https://www.instagram.com/koderiask/" rel="noopener noreferrer">
                                    <k-icon name="instagram" size="20" />
                                </a>
                                <a target="_blank" href="https://open.spotify.com/playlist/09Zy7jEChx3noVIT13huH9" rel="noopener noreferrer">
                                    <k-icon name="spotify" size="20" />
                                </a>
                            </div>
                        </div>
                    </v-list>
                </v-menu>

                <kod-navigation-appbar-user-menu @show-add-post="showAddPost = true" />
            </div>

            <!-- TODO fix this  -->
            <div class="navbar-mobile">
                <div class="navbar-mobile-btn">
                    <v-btn icon @click="drawer = true">
                        <span class="sr-only">{{ $t('navigation.titles.menu') }}</span>
                        <k-icon name="menu" class="navbar-mobile-menu-button" />
                    </v-btn>

                    <v-btn v-if="user" icon light @click="drawerUser = true" class="mr-2">
                        <span class="sr-only">{{ $t('auth.titles.account') }}</span>
                        <ka-user-avatar :size="36" />
                    </v-btn>
                </div>
            </div>
        </v-app-bar>
        <v-app-bar v-else app sticky id="kodAppBar" ref="appbar" :class="appbarStyle">
            <v-toolbar-title @click="$router.push({ path: '/' })" style="cursor: pointer;">
                <ka-logo :light="appbarStyle === 'transparent-light' && windowTop <= 0" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="navbar-desktop">
                <template v-if="!user">
                    <router-link :to="{ name: 'cv-landing-page' }" class="navbar-button-link" style="margin: 0 !important;">
                        {{ $t('navigation.create_new_koderia_biography') }}
                    </router-link>
                </template>
                <template v-else>
                    <template v-if="isMyCv && isCvReady">
                        <router-link :to="{ name: 'profile-cv' }" class="navbar-button-link">{{ $t('global.actions.edit') }}</router-link>
                        <router-link :to="{ name: 'profile-cv', query: { tab: 'nastavenia' } }" class="navbar-button-link">
                            {{ $t('navigation.settings') }}
                        </router-link>
                    </template>
                    <k-button v-show="isCvReady" :appearance="windowTop > 0 ? 'black-outlined' : 'white-outlined'" class="mr-10 __saveCvPdf__">
                        <k-icon name="download" size="20" class="mr-2 text-uppercase" />
                        {{ $t('global.actions.donwload') }} .PDF
                    </k-button>
                    <kod-navigation-appbar-user-menu @show-add-post="showAddPost = true" />
                </template>
            </div>

            <!-- TODO fix this  -->
            <div class="navbar-mobile">
                <div class="navbar-mobile-btn">
                    <template v-if="user">
                        <v-btn icon @click="drawer = true">
                            <span class="sr-only">{{ $t('navigation.titles.menu') }}</span>
                            <k-icon name="menu" size="24" />
                        </v-btn>
                        <v-btn v-if="user" icon light @click="drawerUser = true" class="mr-2">
                            <span class="sr-only">{{ $('auth.titles.account') }}</span>
                            <ka-user-avatar :size="36" />
                        </v-btn>
                    </template>
                    <template v-else>
                        <router-link :to="{ name: 'cv-landing-page' }" class="navbar-button-link" style="margin-right: 1rem !important;">
                            {{ $t('navigation.create_new_koderia_biography') }}
                        </router-link>
                    </template>
                </div>
            </div>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" :right="true" app floating temporary :width="424">
            <div class="drawer-header">
                <h5>{{ $t('navigation.titles.menu') }}</h5>
                <v-btn icon @click="drawer = false" style="margin-right: -0.375rem;">
                    <span class="sr-only">{{ $t('global.actions.close') }}</span>
                    <k-icon name="close" color="#141a1d" />
                </v-btn>
            </div>
            <div v-if="isNotCv" class="drawer">
                <template v-if="!user">
                    <k-button :to="{ name: 'auth-signup' }" block>{{ $t('global.actions.register') }}</k-button>
                    <k-button :to="{ name: 'auth-signin' }" block appearance="primary-outlined" class="mt-2">
                        {{ $t('auth.actions.sign_in_simple') }}
                    </k-button>
                    <hr class="mt-6 mb-6 kod-hr" />
                </template>
                <span class="link">
                    <router-link :to="{ name: 'Home' }">{{ $t('navigation.home') }}</router-link>
                </span>
                <span class="mt-4 link">
                    <router-link :to="{ name: 'project-index' }">{{ $t('navigation.jobs') }}</router-link>
                </span>
                <!--
                <span class="mt-4 link">
                    <index-link :to="{ name: 'Events' }">Podujatia</index-link>
                </span>
                -->
                <span class="mt-4 link">
                    <a href="https://koderia.sk/blog">{{ $t('navigation.blog') }}</a>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: hasUserCv ? 'profile-cv' : 'cv-landing-page' }">
                        {{ $t('navigation.resume') }}<sup class="cv-sup cv-sup--mobile">{{ $t('global.new') }}</sup>
                    </router-link>
                </span>

                <hr class="mt-6 kod-hr" />

                <span class="mt-6 link">
                    <router-link :to="{ name: 'Questionaire' }">{{ $t('navigation.adequate_salary') }}</router-link>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: 'Calculator' }">{{ $t('navigation.calculator') }}</router-link>
                </span>

                <hr class="mt-6 kod-hr" />

                <span class="mt-6 link">
                    <router-link :to="{ name: 'extra' }">{{ $t('navigation.koderia_extra') }}</router-link>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: 'referral' }">
                        Koderia
                        <span class="referral">Referral</span>
                    </router-link>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: 'dictionary-index' }">{{ $t('navigation.koderia_dictionary') }}</router-link>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: 'KoderiaAbout' }">{{ $t('navigation.what_is_koderia') }}</router-link>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: 'AboutUs' }">{{ $t('navigation.about_us') }}</router-link>
                </span>

                <hr class="mt-6 kod-hr" />

                <span class="mt-6 link">
                    <router-link :to="{ name: 'TermsAndConditions' }">{{ $t('navigation.terms_conditions') }}</router-link>
                </span>

                <hr class="mt-6 kod-hr" />

                <div class="mt-6 link-social">
                    <a class="link" target="_blank" href="https://www.facebook.com/koderiask/">
                        <k-icon name="facebook" />
                    </a>
                    <a class="link" target="_blank" href="https://www.linkedin.com/company/koderiask/">
                        <k-icon name="linkedin" />
                    </a>
                    <a class="link" target="_blank" href="https://www.instagram.com/koderiask/">
                        <k-icon name="instagram" />
                    </a>
                    <a class="link" target="_blank" href="https://open.spotify.com/playlist/09Zy7jEChx3noVIT13huH9">
                        <k-icon name="spotify" />
                    </a>
                </div>
            </div>
            <div v-else class="drawer">
                <template v-if="isMyCv && isCvReady">
                    <span class="link">
                        <router-link :to="{ name: 'profile-cv' }">{{ $t('global.actions.edit') }}</router-link>
                    </span>
                    <span class="mt-4 link">
                        <router-link :to="{ name: 'profile-cv', query: { tab: 'nastavenia' } }">{{ $t('navigation.settings') }}</router-link>
                    </span>
                </template>
                <k-button v-show="isCvReady" :appearance="'black'" class="mt-6 __saveCvPdf__">
                    <k-icon name="download" size="20" class="mr-2 text-uppercase" />
                    {{ $t('global.actions.donwload') }} .PDF
                </k-button>
            </div>
        </v-navigation-drawer>
        <v-navigation-drawer v-if="user" v-model="drawerUser" :right="true" app floating temporary :width="424">
            <div class="drawer-header">
                <h5>{{ $t('auth.titles.account') }}</h5>
                <v-btn icon @click="drawerUser = false" style="margin-right: -0.375rem;">
                    <span class="sr-only">{{ $t('global.actions.close') }}</span>
                    <k-icon name="close" color="#141a1d" />
                </v-btn>
            </div>
            <div class="drawer">
                <div v-if="profile" class="link">
                    <router-link :to="{ name: 'profile' }" class="no-underline">
                        <div style="line-height: 1.5 !important;">{{ profile.firstName }} {{ profile.lastName }}</div>
                        <span v-if="profile.profession" class="user-profession" style="line-height: 1.5 !important; text-decoration: none !important;">{{
                            profile.profession
                        }}</span>
                        <span v-else class="user-profession user-profession--empty" style="line-height: 1.5 !important; text-decoration: none !important;"
                            >{{ $t('profile.actions.fill_position') }}</span
                        >
                    </router-link>
                </div>
                <hr class="mt-6 kod-hr" />
                <div class="mt-6 link link--icon">
                    <div class="icon-wrapper">
                        <k-icon name="add" />
                    </div>
                    <a @click.prevent="showAddPost = true" class="ml-4">
                        {{ $t('global.actions.add_post') }}
                    </a>
                </div>
                <hr class="mt-6 kod-hr" />
                <div class="mt-6 link link--icon">
                    <div class="icon-wrapper">
                        <k-icon name="save" />
                    </div>
                    <router-link :to="{ name: 'profile-saved' }" class="ml-4">
                        {{ $t('global.titles.saved') }}
                    </router-link>
                </div>
                <div class="mt-4 link link--icon">
                    <div class="icon-wrapper">
                        <k-icon name="added" />
                    </div>
                    <router-link :to="{ name: 'profile-added' }" class="ml-4">
                        {{ $t('global.titles.added') }}
                    </router-link>
                </div>

                <hr class="mt-6 kod-hr" />

                <span class="mt-6 link">
                    <router-link v-if="hasUserCv" :to="{ name: 'profile-cv' }">{{ $t('navigation.resume') }}</router-link>
                    <router-link v-else :to="{ name: 'cv-landing-page' }">{{ $t('navigation.resume') }}</router-link>
                </span>

                <span class="mt-4 link">
                    <router-link :to="{ name: 'profile' }">{{ $t('navigation.settings') }}</router-link>
                </span>

                <span class="mt-4 link">
                    <a @click="$store.dispatch('AUTH/signOut')">{{ $t('auth.actions.logout') }}</a>
                </span>
            </div>
        </v-navigation-drawer>
        <ka-user-add-post-dialog v-model="showAddPost" />
    </div>
</template>

<script>
import KaLogo from "@web/components/ka-logo";
import KaUserAvatar from "@web/components/ka-user-avatar";
import KaUserAddPostDialog from "@web/user/ka-user-add-post-dialog";
import KodNavigationAppbarUserMenu from "./kod-navigation-appbar-user-menu";

export default {
    name: "kod-navigation-appbar",
    components: {
        KaLogo,
        KaUserAvatar,
        KaUserAddPostDialog,
        KodNavigationAppbarUserMenu
    },
    data() {
        return {
            drawer: false,
            drawerUser: false,
            routes: [],
            showAddPost: false,
            windowTop: null
        };
    },
    computed: {
        initialized() {
            return !!this.$store.state.AUTH.initialized;
        },
        isMyCv() {
            const { url } = this.$store.state.USER.userCv || {};
            return url && window.location.pathname.split("/").pop() === url;
        },
        isCvReady() {
            const { url } = this.$store.state.USER.userCv || {};
            const cvReady = document.getElementById("ready");
            return url && !!cvReady;
        },
        isNotCv() {
            return ["cv-show"].indexOf(this.$route.name) === -1;
        },
        pageInfo: function() {
            return this.$route.meta;
        },
        appbarStyle: function() {
            return this.$store.state.appbar;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        hasUserCv() {
            return this.$store.state.USER.hasUserCv;
        }
    },
    mounted() {
        var that = this;
        window.addEventListener("scroll", function() {
            that.windowTop = window.scrollY;
        });
    },
    watch: {
        appbarStyle: function() {}
    },
    created() {
        this.routes = [
            {
                title: this.$t('navigation.home'),
                name: "Home",
                path: "/",
                menu: "first"
            },
            {
                title: this.$t('navigation.jobs'),
                name: "project-index",
                path: "/praca",
                menu: "first"
            },
            // {
            //     title: "Podujatia",
            //     name: "Events",
            //     path: "/podujatia",
            //     menu: "first",
            // },
            // {
            //     title: 'Blog',
            // },
            {
                title: this.$t('navigation.adequate_salary'),
                name: "Questionaire",
                path: "/adekvatny-plat",
                menu: "second"
            },
            {
                title: this.$t('navigation.about_us'),
                name: "AboutUs",
                path: "/o-nas",
                menu: "second"
            },
            {
                title: this.$t('navigation.policy'),
                name: "TermsAndConditions",
                path: "/ochrana-osobnych-udajov",
                menu: "second"
            }
        ];
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

#kodAppBar {
    box-shadow: 0 0 0.25rem $koderia-input-black;
    background: white;

    &:not(.v-app-bar--is-scrolled).transparent-light {
        background-color: transparent;
        color: white;
        box-shadow: none !important;

        // border-bottom: 0.063rem solid rgba(255, 255, 255, 0.16);

        button.v-btn {
            color: white !important;
            &:hover {
                &::before {
                    opacity: 0;
                }
                background: transparent !important;
                background-color: transparent !important;
            }
        }

        .v-divider {
            border-color: rgba(255, 255, 255, 0.5);
        }
        .navbar-button-link {
            color: white !important;
        }
    }
    &:not(.v-app-bar--is-scrolled).transparent-dark {
        background-color: transparent;
        color: $kod-black;
        box-shadow: none !important;

        // border-bottom: 0.063rem solid rgba(255, 255, 255, 0.16);

        button.v-btn {
            color: $kod-black !important;
            &:hover {
                &::before {
                    opacity: 0;
                }
                background: transparent !important;
                background-color: transparent !important;
            }
        }
        .v-divider {
            border-color: $kod-light-grey;
        }
        .navbar-button-link {
            color: $kod-black !important;
        }
    }
    .v-toolbar__content {
        padding-top: 0;
        padding-bottom: 0;
        @include padding-left-multiplier(3);
        @include padding-right-multiplier(4);

        .v-toolbar__title {
            display: flex;
        }

        @media (max-width: 63.938rem) {
            @include padding-left-multiplier(2);
            @include padding-right-multiplier(2);
        }
    }
    .navbar-button-link {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $koderia-black !important;
        @include margin-right-multiplier(5);
        &.no-right-margin {
            margin-right: 0 !important;
            outline: none !important;
        }
        &::before {
            background-color: white !important;
        }
        &.active,
        &:hover {
            sup {
                display: inline-block !important;
                text-decoration: none !important;
            }
            text-decoration: underline;
        }

        outline: none;
    }
    span.v-btn__content {
        text-transform: capitalize !important;
    }
    .v-divider {
        height: 2rem;
        margin: 1rem 0;
    }
    .user-buttons {
        height: 100%;
        .navbar-button-link {
            padding: unset;

            &:first-child {
                margin: 0 2.5rem;
                color: $koderia-red-alternative;
            }
            &:last-child {
                padding: 0 1.5rem;
                margin-right: 1rem;
                border-radius: 0.063rem;
                height: 2.5rem;
                background-color: $koderia-red-alternative;
                color: white;
                font-weight: bold !important;
            }
        }
    }
}
.appbar-menu-list {
    @include padding-multiplier(4);
    border-radius: 0.125rem;
    width: 18rem;

    display: flex;
    flex-direction: column;

    .appbar-menu-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            @include margin-bottom-multiplier(1);
            @include margin-top-multiplier(1);
            color: $koderia-black;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &.no-margin {
                @include margin-top-multiplier(0);
            }
        }
        .appbar-social {
            // margin-bottom: -0.281rem;
            @include margin-top-multiplier(1);
            > a {
                text-decoration: none;
                i {
                    color: $koderia-black;
                }
                @include margin-right-multiplier(1);
                &:hover i {
                    color: $koderia-grey;
                }
            }
        }
    }
    hr {
        @include margin-bottom-multiplier(2);
        @include margin-top-multiplier(2);

        border: unset;
        border-top: 0.063rem solid $koderia-border-grey;
    }
}
.v-menu__content.theme--light.v-menu__content--fixed,
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    top: 4.5rem !important;
}

.drawer-header {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.drawer {
    padding: 0 1.5rem 8rem 1.5rem;
    display: flex;
    flex-direction: column;
}

.drawer .link {
    position: relative;
}

.drawer .link * {
    font-size: 1rem;
    color: $koderia-black;
    text-decoration: none;
}

.drawer .link:hover a:not(.no-underline) {
    text-decoration: underline;
    sup {
        display: inline-block !important;
        text-decoration: none !important;
    }
}

.drawer .icon-wrapper {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
}

.drawer .link--icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.drawer .link-social {
    display: grid;
    grid-template-columns: repeat(4, 1.5rem);
    grid-template-rows: repeat(1, 1.5rem);
    gap: 0.5rem;
}

.drawer .link-social * {
    text-decoration: none;
}
.drawer .link-social:hover * {
    text-decoration: none;
}
.drawer .link-social .link:hover * {
    color: $koderia-grey;
}

.navbar-desktop {
    display: flex;
}

.navbar-mobile {
    display: none;
    overflow: visible;
}

.navbar-mobile-btn {
    margin-right: -1rem;
}

.navbar-mobile-menu-button {
    color: $koderia-black !important;
}

:not(.v-app-bar--is-scrolled).transparent-light .navbar-mobile-menu-button {
    color: white !important;
}

@media (max-width: 63.938rem) {
    .navbar-desktop {
        display: none !important;
    }

    .navbar-mobile {
        display: flex !important;
    }
}
span.referral {
    color: $kod-light-green !important;
}

.user-profession {
    font-size: 0.875rem !important;
    color: $kod-grey !important;
    text-decoration: none !important;
}

.user-profession--empty {
    color: $kod-light-green !important;
}

.cv-sup {
    font-size: 0.625rem !important;
    display: inline-block !important;
    text-decoration: none !important;
    color: $kod-green !important;
    position: absolute;
    top: 0.875rem;
}

.cv-sup--mobile {
    top: 0.375rem !important;
}
</style>
