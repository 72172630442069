import Vue from "vue";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

import store from "../store";

const initialState = {
    cursor: null,
    itemsById: {}
};

const dataConverter = () => ({
    toFirestore: modelObject => {
        return modelObject;
    },

    fromFirestore: (snapshot, options) => {
        if (snapshot.exists) {
            const data = snapshot.data(options) || {};
            return {
                ...data,
                link: data.url,
                image: data.images ? data.images[data.images.length - 1] : null,
                icon: data.favicons ? data.favicons[0] : null,
                id: snapshot.id
            };
        }

        return null;
    }
});

const actions = {
    async fetchNext(ctx) {
        const now = Date.now();

        let query = firebase
            .firestore()
            .collection("links")
            .withConverter(dataConverter())
            .where("published", "==", true)
            .where("publishedTimestamp", "<=", now)
            .orderBy("publishedTimestamp", "desc")
            .limit(5);

        if (ctx.state.cursor) {
            query = query.startAfter(ctx.state.cursor);
        }

        const snapshot = await query.get();

        snapshot.docs.forEach(doc => {
            ctx.commit("ADD_ITEM", doc.data());
            ctx.commit("SET_CURSOR", doc);
        });
    },

    async fetchById(ctx, id) {
        let doc = await firebase
            .firestore()
            .doc(`links/${id}`)
            .withConverter(dataConverter())
            .get();

        if (doc.exists) {
            ctx.commit("ADD_ITEM", doc.data());
        } else {
            throw new Error("not found");
        }
    }
};

const getters = {
    newsfeed: state => {
        return Object.values(state.itemsById || {});
    },
    newsfeedById: state => id => {
        const { [id]: item } = state.itemsById || {};
        return item;
    }
};

const mutations = {
    ADD_ITEM(state, item) {
        Vue.set(state.itemsById, item.id, item);
    },
    SET_CURSOR(state, cursor) {
        state.cursor = cursor;
    }
};

store.registerModule("NEWSFEED", {
    namespaced: true,
    state: Object.assign({}, initialState),
    actions,
    mutations,
    getters
});
