<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            :fill="color"
            d="M12,41.143A2.857,2.857,0,1,0,14.859,44,2.857,2.857,0,0,0,12,41.143Zm6.681-2.2a2.893,2.893,0,0,0-1.629-1.629c-1.125-.444-3.8-.344-5.052-.344s-3.924-.1-5.052.344a2.893,2.893,0,0,0-1.629,1.629c-.444,1.125-.344,3.806-.344,5.053s-.1,3.925.347,5.054A2.893,2.893,0,0,0,6.95,50.682c1.125.444,3.8.344,5.052.344s3.924.1,5.052-.344a2.893,2.893,0,0,0,1.629-1.629c.447-1.125.344-3.806.344-5.053s.1-3.925-.344-5.053ZM12,48.393a4.387,4.387,0,1,1,3.108-1.285A4.393,4.393,0,0,1,12,48.393Zm4.574-7.945a1.02,1.02,0,1,1,.727-.3,1.025,1.025,0,0,1-.727.3ZM21.429,32H2.571A2.571,2.571,0,0,0,0,34.571V53.429A2.571,2.571,0,0,0,2.571,56H21.429A2.571,2.571,0,0,0,24,53.429V34.571A2.571,2.571,0,0,0,21.429,32Zm-.917,15.536a5.1,5.1,0,0,1-1.385,3.589,5.1,5.1,0,0,1-3.589,1.385c-1.415.08-5.657.08-7.071,0a5.107,5.107,0,0,1-3.589-1.385,5.082,5.082,0,0,1-1.385-3.589c-.08-1.415-.08-5.658,0-7.071a5.081,5.081,0,0,1,1.385-3.589,5.109,5.109,0,0,1,3.589-1.381c1.415-.08,5.657-.08,7.071,0a5.1,5.1,0,0,1,3.589,1.385,5.1,5.1,0,0,1,1.385,3.592C20.591,41.881,20.591,46.119,20.511,47.536Z"
            transform="translate(0 -32)"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#374151"
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
};
</script>
