import config from '@shared/config'
import firebase from 'firebase/app'
import 'firebase/auth'

firebase.initializeApp(config.firebaseConfig)
firebase.auth().languageCode = 'sk'

let currentUserResolved = false

const getCurrentUser = async () => {
  const user = await new Promise((resolve, reject) => {
    if (currentUserResolved) {
      resolve(firebase.auth().currentUser)
    } else {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        currentUserResolved = !!user
        unsubscribe()
        resolve(user)
      }, reject)
    }
  })

  if (user) {
    user.token = await user.getIdTokenResult()
  }

  return user
}

export { firebase as default, getCurrentUser }
